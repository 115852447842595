import ExportType from "../../types/form/ExportType";
import PDFFactoryEntryType from "../../types/pdf/PDFFactoryEntryType";
import SchoolData from "../../assets/schools.json";

import {PDFDocument, PDFPage, RGB} from 'pdf-lib'
import {
    PDFFactory,
    PDFFactoryColors,
    PDFFactoryFontSizes,
    PDFFactoryMargins,
    PDFFactoryNoAnswerText
} from "./PDFFactory";
import Slugger from "./Slugger";
import {OrderedMap, Map} from "immutable";
import MultiSelectEntryType from "../../types/form/MultiSelectEntryType";
import {LocalStorage} from "./LocalStorage";


const addTextToPage = (
    page: PDFPage,
    text: string,
    yOffset: number,
    size: number,
    options: object
) => {
    const margin = 10

    const newYOffset = yOffset - margin - size

    page.drawText(text, {
        ...options,
        y: newYOffset
    })

    return (text: string, size: number, options: object) =>
        addTextToPage(page, text, newYOffset, size, options)
}

const getProfile = (): PDFFactoryEntryType => {

    const level = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is_level') ?? '';
    return ['havo', 'vwo'].includes(level) ? {
        type: 'columns',
        col1: 'Profiel ' + level + ':',
        col2: LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is_profile') ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    } : {type: "empty", size: 0};
};

const getCourses = (): PDFFactoryEntryType => {

    const level = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is_level') ?? '';
    const courses = getLocalStorageValuesByPrefix('lobcv_answers_personal_information_mijn-vooropleiding-is[courses][' + level + ']');
    return ['havo', 'vwo'].includes(level) ? {
            type: 'columns',
            col1: 'Vakken ' + level + ":",
            col2: courses,
            size: PDFFactoryFontSizes.normal
        } :
        {type: "empty", size: 0}
};

const getOtherCourses = (): PDFFactoryEntryType => {

    const level = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is_level') ?? ''
    const otherCourses = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is[other-courses][' + level + ']') ?? ''
    return ['havo', 'vwo'].includes(level) && otherCourses.trim() !== '' ? {
            type: 'columns',
            col1: "Andere vakken:",
            col2: otherCourses ?? PDFFactoryNoAnswerText,
            size: PDFFactoryFontSizes.normal
        } :
        {type: "empty", size: 0}
};

const getSubjectPws = (): PDFFactoryEntryType => {

    const level = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is_level') ?? ''
    const subjectPws = LocalStorage.get('lobcv_answers_personal_information_mijn-vooropleiding-is[subject-pws][' + level + ']') ?? ''

    return ['havo', 'vwo'].includes(level) && subjectPws !== '' ? {
            type: 'columns',
            col1: "Onderwerp profielwerkstuk: ",
            col2: subjectPws ?? PDFFactoryNoAnswerText,
            size: PDFFactoryFontSizes.normal
        } :
        {type: "empty", size: 0}
};

const getSchoolName = (key: string): string => {

    const schoolId = Number(LocalStorage.get(key))

    if (SchoolData[schoolId]) {
        return SchoolData[schoolId].name
    }
    return ''
}

const getSchoolProgram = (schoolKey: string, programKey: string): string => {

    const schoolId = Number(LocalStorage.get(schoolKey))
    const programId = Number(LocalStorage.get(programKey))

    if (SchoolData[schoolId]) {
        // Loop through the programs of the school and return the name of the program with the same id as the programId
        for (let i = 0; i < SchoolData[schoolId].programs.length; i++) {
            if (SchoolData[schoolId].programs[i].id === programId) {
                return SchoolData[schoolId].programs[i].name
            }
        }
    }
    return ''
}

const getLocalStorageValuesByPrefix = (prefix: string): string => {
    let value_string = '';
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(prefix)) {
            const course = key.match(/\[([^\]]*)\]$/)?.[1] ?? '';
            const capitalizedCourse = course.charAt(0).toUpperCase() + course.slice(1);
            value_string += LocalStorage.get(key) ? capitalizedCourse + ', ' : '';
        }
    }
    return value_string.slice(0, -2);
};;

function* renderActivity(activities: string): Generator<PDFFactoryEntryType> {
    const activityMap =
        OrderedMap<string, MultiSelectEntryType>(JSON.parse(activities))

    for (const openDay of activityMap.valueSeq()) {

        yield {
            type: "text",
            text: "Welke onderwijsinstelling",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {
            type: "text",
            text: openDay.school,
            size: PDFFactoryFontSizes.smaller
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield {
            type: "text",
            text: "Welke opleiding",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {
            type: "text",
            text: openDay.program,
            size: PDFFactoryFontSizes.smaller
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield {
            type: "text",
            text: "Geef een min- en pluspunt van deze activiteit",
            size: PDFFactoryFontSizes.normal,
            bold: true
        }
        yield {
            type: "text",
            text: openDay.details,
            size: PDFFactoryFontSizes.smaller
        }
        yield {type: "margin", size: PDFFactoryMargins.large}

    }

    yield {type: "margin", size: PDFFactoryMargins.small}
}

function* getPdfContent1(): Generator<PDFFactoryEntryType> {
    yield {
        type: "header",
        text: "Persoonlijke informatie",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.purple as RGB,
        image: "//" + window.location.host + "/assets/images/personal-information-icon.png",
    }
}

function* getPdfContent(): Generator<PDFFactoryEntryType> {

    const profile = getProfile()
    const courses = getCourses()
    const otherCourses = getOtherCourses()
    const subjectPws = getSubjectPws()

    yield {
        type: "image",
        image: "//" + window.location.host + "/assets/images/lobcv_logo.png",
        size: 50
    }
    yield {
        type: "margin",
        size: PDFFactoryMargins.large / 2
    }
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_naam") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.subheader,
        bold: true
    }
    yield {
        type: "margin",
        size: PDFFactoryMargins.large
    }
    yield {
        type: "header",
        text: "Persoonlijke informatie",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.purple as RGB,
        image: "//" + window.location.host + "/assets/images/personal-information-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "columns",
        col1: "Woonplaats:",
        col2: LocalStorage.get("lobcv_answers_personal_information_woonplaats") ?? PDFFactoryNoAnswerText,
        col3: "Geboortedatum:",
        col4: (LocalStorage.get("lobcv_answers_personal_information_geboortedatum") ?? PDFFactoryNoAnswerText).split("-").reverse().join("/"),
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "columns",
        col1: "Nationaliteit:",
        col2: LocalStorage.get("lobcv_answers_personal_information_nationaliteit") ?? PDFFactoryNoAnswerText,
        col3: "School:",
        col4: LocalStorage.get("lobcv_answers_personal_information_naam-van-je-school") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "columns",
        col1: "Niveau:",
        col2: LocalStorage.get("lobcv_answers_personal_information_mijn-vooropleiding-is_level") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}

    if (profile.type !== 'empty')
        yield {type: "margin", size: PDFFactoryMargins.small}

    if (courses.type !== 'empty')
        yield {type: "margin", size: PDFFactoryMargins.small}

    if (otherCourses.type !== 'empty')
        yield {type: "margin", size: PDFFactoryMargins.small}

    if (subjectPws.type !== 'empty')
        yield {type: "margin", size: PDFFactoryMargins.large}

    yield {
        type: "text",
        text: "Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (1)",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: 'columns',
        col1: "School:",
        col2: getSchoolName("lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: 'columns',
        col1: "Opleiding:",
        col2: getSchoolProgram(
            'lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school',
            'lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_program'
        ) ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (2)",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: 'columns',
        col1: "School:",
        col2: getSchoolName('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool') ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: 'columns',
        col1: "Opleiding:",
        col2: getSchoolProgram(
            'lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool',
            'lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondprogram'
        ) ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "text",
        text: "Wat doe ik graag in mijn vrije tijd:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_additional_information_wat-doe-ik-graag-in-mijn-vrije-tijd") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Mijn bijbaan:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_nationaliteit") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Mijn stage-ervaring:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_nationaliteit") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Mijn vrijwilligerswerk:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_additional_information_mijn-vrijwilligerswerk") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Overige activiteiten:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_additional_information_overige-activiteiten") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Bijzonderheden die van invloed kunnen zijn op mijn studieverloop (zoals dyslexie, autisme, topsporter, mantelzorger etc.):",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_additional_information_bijzonderheden-die-van-invloed-kunnen-zijn-op-mijn-studieverloop-zoals-dyslexie-autisme-topsporter-mantelzorger-etc") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Welke van bovenstaande (werk-)ervaringen speelt een rol in het maken van mijn studiekeuze:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_additional_information_welke-van-bovenstaande-werkervaring-speelt-een-rol-in-het-maken-van-mijn-studiekeuze") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "header",
        text: "Vaardigheden en kwaliteiten",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.blue as RGB,
        image: "//" + window.location.host + "/assets/images/skills-and-competencies-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "columns",
        col1: "",
        col2: "",
        col3: "kan ik nog niet:",
        col4: "\t\t\t  doe ik perfect",
        bold: true,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Plannen en zelfstandig werken",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_plannen-en-zelfstandig-werken") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Informatie zoeken en verwerken",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_informatie-zoeken-en-verwerken") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "(Lange) teksten lezen en leren",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_lange-teksten-lezen-en-leren") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Samenwerken",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_samenwerken") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Analyseren",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_analyseren") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "ICT gebruiken",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_ict-gebruiken") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Schriftelijk communiceren",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_schriftelijk-communiceren-bijv-een-verslag-maken") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Presenteren / uitleg geven",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_presenteren-uitleg-geven") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "range",
        text: "Reflecteren",
        range: parseInt(LocalStorage.get("lobcv_answers_personal_information_reflecteren") ?? '50') ?? 50,
        size: PDFFactoryFontSizes.normal
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "text",
        text: "Kies nu minimaal 3 kernkwaliteiten waar je sterk in bent en geef van daar voorbeelden van:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_kies-nu-minimaal-3-kernkwaliteiten-waar-je-sterk-in-bent-en-geef-van-daar-voorbeelden-van") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Kies ook 3 kernkwaliteiten waarin je jezelf nog kan verbeteren:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_kies-ook-3-kernkwaliteiten-waarin-je-jezelf-nog-kan-verbeteren") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Welke van mijn gekozen kernkwaliteiten denk ik nodig te hebben bij mijn vervolgopleiding:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_personal_information_welke-van-mijn-gekozen-kernkwaliteiten-denk-ik-nodig-te-hebben-bij-mijn-vervolgopleiding") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "header",
        text: "Studiekeuzeproces en -oriëntatie",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.yellow as RGB,
        image: "//" + window.location.host + "/assets/images/orientation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}

    const openDays = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[open-dagen][entries]") ?? null
    const openDaysChecked = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[open-dagen][checked]") === "true"

    const trailStudying = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[proefstuderen][entries]") ?? null
    const trailStudyingChecked = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[proefstuderen][checked]") === "true"


    const experienceDay = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[meeloopdagen][entries]") ?? null
    const experienceDayChecked = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[meeloopdagen][checked]") === "true"


    const otherActivities = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[andere-studiekeuzeactiviteiten-bijv-zomerschool][entries]") ?? null
    const otherActivitiesChecked = LocalStorage.get("lobcv_answers_orientation_er-worden-door-alle-hogescholen-activiteiten-aangeboden-om-jou-te-helpen-met-je-studiekeuze-welke-activiteiten-heb-je-al-ondernomen[andere-studiekeuzeactiviteiten-bijv-zomerschool][checked]") === "true"


    if (openDaysChecked && openDays) {
        yield {
            type: "text",
            text: "Open dagen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield * renderActivity(openDays);
    }

    if (trailStudyingChecked && trailStudying) {
        yield {
            type: "text",
            text: "Proefstuderen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield * renderActivity(trailStudying);
    }

    if (experienceDayChecked && experienceDay) {
        yield {
            type: "text",
            text: "Meeloopdagen",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield * renderActivity(experienceDay);
    }

    if (otherActivitiesChecked && otherActivities) {
        yield {
            type: "text",
            text: "Andere studiekeuzeactiviteiten",
            size: PDFFactoryFontSizes.subheader,
            bold: true
        }
        yield {type: "margin", size: PDFFactoryMargins.small}

        yield * renderActivity(otherActivities);
    }

    yield {
        type: "text",
        text: "Met wie bespreek ik mijn studiekeuzeproces?",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }

    const orientationWithRaw = LocalStorage.get("lobcv_answers_orientation_met-wie-bespreek-ik-mijn-studiekeuzeproces[options]") ?? "{}";
    const orientationWith = Map<string, boolean>(JSON.parse(orientationWithRaw));

    if (orientationWith.get('ouders')) {
        yield {
            type: "text",
            text: "Ouders",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('andere-familie')) {
        yield {
            type: "text",
            text: "Andere familie",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('vrienden')) {
        yield {
            type: "text",
            text: "Vrienden",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('decaan')) {
        yield {
            type: "text",
            text: "Decaan",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('mentor')) {
        yield {
            type: "text",
            text: "Mentor",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('student')) {
        yield {
            type: "text",
            text: "student",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('iemand-uit-het-beroepenveld')) {
        yield {
            type: "text",
            text: "iemand uit het beroepenveld",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('docent')) {
        yield {
            type: "text",
            text: "Docent",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('studiekeuzeadviseur')) {
        yield {
            type: "text",
            text: "Studiekeuzeadiviseur",
            size: PDFFactoryFontSizes.smaller
        }
    }

    if (orientationWith.get('studiekeuzeadviseur')) {
        yield {
            type: "text",
            text: "Studiekeuzeadiviseur",
            size: PDFFactoryFontSizes.smaller
        }
    }
    
    const orientationOther = LocalStorage.get('lobcv_answers_orientation_met-wie-bespreek-ik-mijn-studiekeuzeproces[other]') ?? '';

    if (orientationOther) {
        yield {type: "margin", size: PDFFactoryMargins.small}
        yield {
            type: "text",
            text: `Anders, namelijk`,
            size: PDFFactoryFontSizes.smaller,
            bold: true
        }
        yield {
            type: "text",
            text: orientationOther,
            size: PDFFactoryFontSizes.smaller
        }
    }

    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_orientation_met-wie-bespreek-ik-mijn-studiekeuzeproces") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "wie is voor mij het meest belangrijk bij mijn studiekeuze en waarom?",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_orientation_wie-is-voor-mij-het-meest-belangrijk-bij-mijn-studiekeuze") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Wat is het beste advies dat ik heb ontvangen?",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_orientation_wat-is-het-beste-advies-dat-ik-heb-ontvangen") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Wie of wat heeft mij het beste geholpen bij mijn studiekeuze en waarom?",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_orientation_wie-of-wat-heeft-mij-het-beste-geholpen-bij-mijn-studiekeuze-en-waarom") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "header",
        text: "Motivatie en drijfveren",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.red as RGB,
        image: "//" + window.location.host + "/assets/images/motivation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "text",
        text: "Ik ben geïnteresseerd in de volgende opleiding(en)",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_motivation_ik-ben-genteresseerd-in-de-volgende-opleidingen") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Het belangrijkste bij het maken van een studiekeuze is voor mij:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_motivation_het-belangrijkste-bij-het-maken-van-een-studiekeuze-is-voor-mij") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "header",
        text: "Verwachtingen en voorbereiding",
        size: PDFFactoryFontSizes.header,
        backgroundColor: PDFFactoryColors.green as RGB,
        image: "//" + window.location.host + "/assets/images/expectation-icon.png",
    }
    yield {type: "margin", size: PDFFactoryMargins.large}
    yield {
        type: "text",
        text: "Wat verwacht ik dat op mijn vervolgopleiding anders zal zijn dan in mijn huidige opleiding?",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: LocalStorage.get("lobcv_answers_orientation_wat-verwacht-ik-dat-op-mijn-vervolgopleiding-anders-zal-zijn-dan-in-mijn-huidige-opleiding") ?? PDFFactoryNoAnswerText,
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Hoeveel uur per week denk ik straks kwijt te zijn aan:",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Lesuren",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Lesuren") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "pie",
        size: 260,
    }
    yield {
        type: "text",
        text: "Reistijd",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Reistijd") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Zelfstudie",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Zelfstudie") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Bijbaantje",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Bijbaantje") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Ontspanning",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Ontspanning") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Sporten",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Sporten") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Huishouden",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Huishouden") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Zorgtaken",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Zorgtaken") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Slapen",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Slapen") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}
    yield {
        type: "text",
        text: "Anders",
        size: PDFFactoryFontSizes.normal,
        bold: true
    }
    yield {
        type: "text",
        text: LocalStorage.get("Anders") ?? "0",
        size: PDFFactoryFontSizes.smaller
    }
    yield {type: "margin", size: PDFFactoryMargins.small}

}

const PDFExport: ExportType = {

    serve: async () => {

        window.dispatchEvent(new CustomEvent("lobcv:export:pdf:start"))

        const pdfDoc = await PDFFactory.generate([...getPdfContent()])

        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], {type: 'application/pdf'})
        const url = URL.createObjectURL(blob)

        // sleep 2 seconds
        await new Promise(resolve => setTimeout(resolve, 2000))

        const name = LocalStorage.get("lobcv_answers_personal_information_naam") ?? "pdf"

        // timestamp d-m-Y
        const date = new Date()
        const timestamp = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`

        // Create an anchor element and trigger a download
        const a = document.createElement('a');
        a.href = url;
        a.download = `lobcv-${Slugger.slugify(name)}-${timestamp}.pdf`; // The name of the file to be saved
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }

}

export {PDFExport};
