import FormStorageType from "../../types/form/FormStorageType";

const LocalStorage: FormStorageType = {
    set: (key, value) => {
        value === "" ?
            localStorage.removeItem(key) :
            localStorage.setItem(key, value);
    },
    get: (key) => {
        return localStorage.getItem(key);
    },
    getJson: () => {
        return {};
    }
}

export {LocalStorage};
