import React, {useState} from "react";
import HorizontalInput from "./layout/HorizontalInput";
import RadioField from "./common/RadioField";
import CheckboxField from "./common/CheckboxField";
import ChapterTitle from "../chapters/ChapterTitle";
import {LocalStorage} from "../../services/form/LocalStorage";
import classes from "../../assets/courses.json";
import TextareaInputField from "./common/TextareaInputField";
import TextInput from "./TextInput";
import TextInputField from "./common/TextInputField";
import {Text} from "recharts";
import ChapterText from "../chapters/ChapterText";
import CheckboxInput from "./CheckboxInput";
import Slugger from "../../services/form/Slugger";

type SelectInputPropsType = {
    label: string,
    name: string
}

type SelectEducationInputState = {
    level: string,
    profile: string,
    profiles: string[]
    class: string,
    selectedClasses: string[]
}


const SelectEducationInputState = {
    default: (name: string): SelectEducationInputState => ({
        level: LocalStorage.get(`${name}_level`) ?? '',
        profile: LocalStorage.get(`${name}_profile`) ?? '',
        class: '',
        profiles: ['C&M', 'E&M', 'N&T', 'N&G'],
        selectedClasses: []
    }),
    updaters: {
        setLevel: (level: string, name: string) => (state: SelectEducationInputState): SelectEducationInputState => {
            LocalStorage.set(name, level);
            return {...state, level: level};
        },
        setProfile: (profile: string, name: string) => (state: SelectEducationInputState): SelectEducationInputState => {
            LocalStorage.set(name, profile);
            return {...state, profile: profile};
        }
    }
}

export default function SelectEducationInput(props: SelectInputPropsType) {

    const [state, setState] = useState<SelectEducationInputState>(SelectEducationInputState.default(props.name));

    const levels = ['mbo', 'havo', 'vwo', 'anders']

    return <>
        <ChapterTitle>{props.label}</ChapterTitle>

        <HorizontalInput label="Niveau" name={props.name}>
            <>
                <div className="grid grid-cols-5">
                    {
                        levels.map((levelLabel, index) => (
                            <>
                                <RadioField
                                    value={levelLabel}
                                    name={props.name + '_level'}
                                    onChange={(e) => {
                                        setState(SelectEducationInputState.updaters.setLevel(e.currentTarget.value, props.name + '_level'))
                                    }}
                                    label={levelLabel}
                                    key={index}
                                    defaultChecked={state.level === levelLabel}
                                />
                            </>
                        ))
                    }
                </div>
            </>
        </HorizontalInput>

        {
            ['vwo', 'havo', 'vmbo'].includes(state.level) ?
                <HorizontalInput label={"Profiel " + state.level} name={props.name}>
                    <>
                        <div className="grid grid-cols-5">
                            {
                                state.profiles.map((profileLabel, index) => (
                                    <>
                                        <RadioField
                                            value={profileLabel}
                                            name={props.name + '_profile'}
                                            onChange={(e) => {
                                                setState(SelectEducationInputState.updaters.setProfile(e.currentTarget.value, props.name + '_profile'))
                                            }}
                                            label={profileLabel}
                                            key={index}
                                            defaultChecked={state.profile === profileLabel}
                                        />
                                    </>
                                ))
                            }
                        </div>
                    </>
                </HorizontalInput> : null
        }

        {
            ['vwo', 'havo'].map((level) => (
                <>
                    {
                        [level].includes(state.level) ?
                            <>
                                <HorizontalInput
                                    labelTop
                                    label={`Vakken ${state.level}`} name={props.name}>
                                    <>
                                        <div className="grid grid-cols-3">
                                            {
                                                classes[state.level as "vwo" | "havo"].map((classLabel, index) => (
                                                    <>
                                                        <CheckboxInput
                                                            key={index}
                                                            name={`${props.name}[courses][${state.level}][` + Slugger.slugify(classes[state.level as "vwo" | "havo"][index].name) + `]`}
                                                            label={classes[state.level as "vwo" | "havo"][index].name}
                                                        />
                                                    </>
                                                ))
                                            }
                                        </div>
                                    </>
                                </HorizontalInput>
                                <HorizontalInput label={""} name={""}>
                                    <TextInput textarea label={"Andere vakken"}
                                               name={`${props.name}[other-courses][${state.level}]`}/>
                                </HorizontalInput>
                                <TextInput label={"Onderwerp profielwerkstuk "}
                                           name={`${props.name}[subject-pws][${state.level}]`}/>
                            </>
                            : null
                    }
                </>
            ))
        }

        {
            ['mbo'].includes(state.level) ?
                <>
                    <TextInput label={"Opleiding"} name={`${props.name}[program][${state.level}]`}/>
                    <TextInput textarea label={"Vakken"} name={`${props.name}[other-courses][${state.level}]`}/>
                    <TextInput textarea label={"Keuzedeel voorbereiding HBO"}
                               name={`${props.name}[other-courses][${state.level}]`}/>
                    <div className="-mt-3">
                        <HorizontalInput label="">
                            <ChapterText><small>(indien van toepassing)</small></ChapterText>
                        </HorizontalInput>
                    </div>
                    <TextInput label={"Onderwerp eindexamen opdracht"}
                               name={`${props.name}[subject-pws][${state.level}]`}/>
                </> : null
        }

        {
            ['anders'].includes(state.level) ?
                <>
                    <TextInput textarea label={"Toelichting"} name={`${props.name}[detail][${state.level}]`}/>
                </> : null
        }

    </>
}
