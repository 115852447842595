import React, {useState} from "react";
import HorizontalInput from "./layout/HorizontalInput";
import RadioField from "./common/RadioField";
import CheckboxField from "./common/CheckboxField";
import ChapterTitle from "../chapters/ChapterTitle";
import SchoolData from "../../assets/schools.json";
import {LocalStorage} from "../../services/form/LocalStorage";

type SelectInputPropsType = {
    label: string,
    name: string
}

type SelectSchoolInputState = {
    school: number,
    program: number,
    secondSchool: number,
    secondProgram: number
}

const SelectSchoolInputState = {
    default: (): SelectSchoolInputState => ({
        school: LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school') ? parseInt(LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_school') ?? '0') : 0,
        program: LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_program') ? parseInt(LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_program') ?? '0') : 0,
        secondSchool: LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool') ? parseInt(LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondschool') ?? '0') : 0,
        secondProgram:  LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondprogram') ? parseInt(LocalStorage.get('lobcv_answers_personal_information_voor-welke-opleiding-heb-je-gekozen-of-overweeg-je-studiekeuze-1_secondprogram') ?? '0') : 0,
    }),
    updaters: {
        setSchool: (school: number, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, school.toString());
            return {...state, school: school};
        },
        setProgram: (program: number, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, program.toString());
            return {...state, program: program};
        },
        setSecondSchool: (secondSchool: number, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, secondSchool.toString());
            return {...state, secondSchool: secondSchool};
        },
        setSecondProgram: (secondProgram: number, name: string) => (state: SelectSchoolInputState): SelectSchoolInputState => {
            LocalStorage.set(name, secondProgram.toString());
            return {...state, secondProgram: secondProgram};
        },
    }
}

export default function SelectSchoolInput(props: SelectInputPropsType) {

    const [state, setState] = useState<SelectSchoolInputState>(SelectSchoolInputState.default());

    const levels = ['vmbo', 'havo', 'vwo']

    return <>
        <ChapterTitle>{props.label}</ChapterTitle>

        <HorizontalInput label="School" name={props.name + "_school"}>
            <>
                <div className="grid">
                    <select id={props.name + "_school"} value={state.school.toString()}
                            onChange={(e) => {setState(SelectSchoolInputState.updaters.setSchool(parseInt(e.currentTarget.value), props.name + "_school"))}}
                            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full">
                        <option value="0">Selecteer</option>
                        {
                            SchoolData.map((school, index) => (
                                <option key={index} value={index}>{school.name}</option>
                            )).sort(function (a, b) {
                                if (a.props.children < b.props.children) {
                                    return -1;
                                }
                                if (a.props.children > b.props.children) {
                                    return 1;
                                }
                                return 0;
                            })
                        }
                    </select>
                </div>
            </>
        </HorizontalInput>

        <HorizontalInput label="Opleiding" name={props.name + "_program"}>
            <>
                <div className="grid">
                    <select id={props.name + "_program"} value={state.program}
                            onChange={(e) => {setState(SelectSchoolInputState.updaters.setProgram(parseInt(e.currentTarget.value), props.name + "_program"))}}
                            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent">
                        <option value="0">Selecteer</option>
                        {
                            state.school > 0 ?
                                SchoolData[state.school].programs.map((program, index) => (
                                    <option key={index} value={program.id}>{program.name}</option>
                                )).sort(function (a, b) {
                                    if (a.props.children < b.props.children) {
                                        return -1;
                                    }
                                    if (a.props.children > b.props.children) {
                                        return 1;
                                    }
                                    return 0;
                                }) : null
                        }
                    </select>
                </div>
            </>
        </HorizontalInput>

        <ChapterTitle>Voor welke opleiding heb je gekozen of overweeg je? Studiekeuze (2)</ChapterTitle>

        <HorizontalInput label="School" name={props.name + "_secondschool"}>
            <>
                <div className="grid">
                    <select id={props.name + "_school"} value={state.secondSchool.toString()}
                            onChange={(e) => {setState(SelectSchoolInputState.updaters.setSecondSchool(parseInt(e.currentTarget.value), props.name + "_secondschool"))}}
                            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent w-full">
                        <option value="0">Selecteer</option>
                        {
                            SchoolData.map((school, index) => (
                                <option key={index} value={index}>{school.name}</option>
                            )).sort(function (a, b) {
                                if (a.props.children < b.props.children) {
                                    return -1;
                                }
                                if (a.props.children > b.props.children) {
                                    return 1;
                                }
                                return 0;
                            })
                        }
                    </select>
                </div>
            </>
        </HorizontalInput>

        <HorizontalInput label="Opleiding" name={props.name + "_secondprogram"}>
            <>
                <div className="grid">
                    <select id={props.name + "_program"} value={state.secondProgram}
                            onChange={(e) => {setState(SelectSchoolInputState.updaters.setSecondProgram(parseInt(e.currentTarget.value), props.name + "_secondprogram"))}}
                            className="bg-white px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-lobcv-purple-500 focus:border-transparent">
                        <option value="0">Selecteer</option>
                        {
                            state.school > 0 ?
                                SchoolData[state.secondSchool].programs.map((program, index) => (
                                    <option key={index} value={program.id}>{program.name}</option>
                                )).sort(function (a, b) {
                                    if (a.props.children < b.props.children) {
                                        return -1;
                                    }
                                    if (a.props.children > b.props.children) {
                                        return 1;
                                    }
                                    return 0;
                                }) : null
                        }
                    </select>
                </div>
            </>
        </HorizontalInput>
    </>
}
